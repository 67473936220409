import React from "react";
import { graphql } from "gatsby";
import { Wrapper } from "../components/site/Wrapper";
import { Image } from "../components/site/Image";
import { Button } from "../components/site/Button";

const Contact = () => {
  return (
    <Wrapper>
      <div className="container gx-1">
        <h1 className="fw-bold mx-2 col-lg-8">Privacy Policy</h1>
        <div className="my-3 mx-2 col-lg-8">Last Updated: October 2022</div>
        <div className="my-3 mx-2 col-lg-8">
          <div className="h3">Information that is gathered from visitors</div>
          <div className="">
            In common with other websites, log files are stored on the web
            server saving details such as the visitor's IP address, browser
            type, referring page and time of visit. Cookies may be used to
            remember visitor preferences when interacting with the website.
            Where registration is required, the visitor's email and a username
            will be stored on the server.
          </div>
        </div>
        <div className="my-3 mx-2 col-lg-8">
          <div className="h3">How the Information is used</div>
          <div className="">
            The information is used to enhance the vistor's experience when
            using the website to display personalised content and possibly
            advertising. E-mail addresses will not be sold, rented or leased to
            3rd parties. E-mail may be sent to inform you of news of our
            services or offers by us or our affiliates.
          </div>
        </div>

        <div className="my-3 mx-2 col-lg-8">
          <div className="h3">Visitor Options</div>
          <div className="">
            If you have subscribed to one of our services, you may unsubscribe
            by following the instructions which are included in e-mail that you
            receive. You may be able to block cookies via your browser settings
            but this may prevent you from access to certain features of the
            website.
          </div>
        </div>

        <div className="my-3 mx-2 col-lg-8">
          <div className="h3">Cookies</div>
          <div className="">
            Cookies are small digital signature files that are stored by your
            web browser that allow your preferences to be recorded when visiting
            the website. Also they may be used to track your return visits to
            the website. 3rd party advertising companies may also use cookies
            for tracking purposes.
          </div>
        </div>

        <div className="my-3 mx-2 col-lg-8">
          <div className="h3">Google Ads</div>
          <div className="">
            Google, as a third party vendor, uses cookies to serve ads. Google's
            use of the DART cookie enables it to serve ads to visitors based on
            their visit to sites they visit on the Internet. Website visitors
            may opt out of the use of the DART cookie by visiting the Google ad
            and content network privacy policy.
          </div>
          <div>
            <a href="http://www.google.com/policies/privacy/partners/">How Google uses data when you use our partners’ sites or apps.</a>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

// export const query = graphql`
//   # query will go here
//   {
//     allBooksJson {
//       edges {
//         node {
//           title
//           subTitle
//           slug
//           image
//           amazonUrl
//           amazonPrice
//           shortUrl
//           pages
//         }
//       }
//     }
//   }
// `;

export default Contact;
